import { createContext, useContext } from 'react';
import SdmClient, { UserProfile } from 'sdm-js-sdk';

export interface SdmContextProps {
  client: SdmClient;
  handshake: any;
  token?: string;
  userProfile: UserProfile;
}

export const SdmContext = createContext<SdmContextProps | null>(null);

export function useSdmContext() {
  return useContext(SdmContext);
}
