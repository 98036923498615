import { RouterProvider } from 'react-router-dom';
import routes from './router';
import './App.css';
import ConfigProvider from './providers/ConfigProvider';
import { useSdmAuth } from './hooks/useSdmAuth';
import { SdmContext } from './contexts/SdmContext';
import { ReactComponent as LoadingOutlined } from '../src/assets/img/icons/circle-loading-outlined.svg';

function App() {
  const contextValue = useSdmAuth();
  if (!contextValue) {
    return (
      <div className="flex flex-col h-[270px] items-center justify-center">
        <div className="text-[120px] text-[#FC6F4E]">
          <LoadingOutlined />
        </div>
        Laoding...
      </div>
    );
  }
  return (
    <SdmContext.Provider value={contextValue}>
      <ConfigProvider>
        <RouterProvider
          router={routes}
          fallbackElement={''}
          future={{ v7_startTransition: true }}
        />
      </ConfigProvider>
    </SdmContext.Provider>
  );
}

export default App;
