export const formatWallet = (token: string, before = 6, after = 4) => {
  if (token) {
    const start = token.substring(0, before);
    const end = token.substring(token.length - after, token.length);
    if (token.length < 10) {
      return token;
    }
    return `${start}...${end}`;
  }
  return token;
};

export const isIos = (): boolean => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export const isAndroid = (): boolean => {
  return /Android/.test(navigator.userAgent);
}

export const getDefaultAvatar = (id: string, size = 120) => {
  return `https://static.sending.me/beam/${size}/${id}?colors=FC774B,FFB197,B27AFF,DAC2FB,F0E7FD&square=true`;
};
