import { ReactNode, memo } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import { useRequest } from 'ahooks';
import { getPayConfig } from '../api/pay';

interface IProps {
  children: ReactNode;
}

export default memo(function ConfigProvider(props: IProps) {
  const { children } = props;
  const { loading, data } = useRequest(() => getPayConfig());
  if (loading) {
    return <></>;
  }

  if (data) {
    return <ConfigContext.Provider value={data}>{children}</ConfigContext.Provider>;
  }
});
