import { useEffect, useState } from 'react';
import SdmClient, { PlatformType, UserProfile } from 'sdm-js-sdk';
// import { getSdmAccessToken } from '../api/auth';
import { isAndroid, isIos } from '../lib/util';
import { SdmContextProps } from '../contexts/SdmContext';

export function useSdmAuth() {
  const [sdmContext, setSdmContext] = useState<SdmContextProps | null>(null);
  // const url = new URL(location.href);

  // const userId = url.searchParams.get('userId');

  useEffect(() => {
    const platformType: PlatformType = isIos()
      ? PlatformType.iOS
      : isAndroid()
      ? PlatformType.Android
      : PlatformType.Web;
    const client = new SdmClient({ type: platformType });
    const handshake = (client.platform as any).handshake;

    // const mxToken = localStorage.getItem('mx_access_token');
    // const userToken = userId ? localStorage.getItem(`sdn_${userId}`) : '';

    // if (!mxToken || mxToken !== userToken) {

    client.request(
      'authorizeNative',
      {
        clientId: import.meta.env.VITE_APP_CLIENT_ID,
        redirectUri: import.meta.env.VITE_APP_ORIGIN,
      },
      async (data) => {
        if (data?.authorization_code) {
          // const { access_token } = await getSdmAccessToken(
          //   new URLSearchParams({
          //     client_id: import.meta.env.VITE_APP_CLIENT_ID,
          //     redirect_uri: import.meta.env.VITE_APP_ORIGIN,
          //     grant_type: 'authorization_code',
          //     code: data.authorization_code,
          //   }).toString()
          // );
          // if (access_token) {
          //   localStorage.setItem('mx_access_token', access_token);
          client.getMyProfile((profile: UserProfile) => {
            // localStorage.setItem(`sdn_${profile.userId}`, access_token);
            setSdmContext({
              // token: access_token,
              client,
              handshake,
              userProfile: profile,
            });
          });
          // }
        } else {
          alert('Authorization failed, please reload page an try again');
        }
      }
    );
    // } else {
    //   client.getMyProfile((profile: UserProfile) => {
    //     setSdmContext({
    //       token: localStorage.getItem('mx_access_token') as string,
    //       client,
    //       handshake,
    //       userProfile: profile,
    //     });
    //   });
    // }
  }, []);

  return sdmContext;
}
