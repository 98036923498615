import { PayRecordParams, PayRecordResult, PayResult, WebConfig } from '../@types/pay';
import http from './http';

export async function getPayConfig() {
  return http.get<WebConfig>('/get_pay_config');
}

export async function addPayRecord(params: PayRecordParams) {
  return http.post<PayRecordResult>('/add_pay_record', params);
}

export async function getPayResult(wallet: string) {
  return http.get<PayResult>('/is_wallet_pay_finish', { params: { wallet } });
}
