import { createBrowserRouter } from 'react-router-dom';
import { lazy } from 'react';

const routes = createBrowserRouter([
  {
    path: '/',
    index: true,
    Component: lazy(() => import('../views/payment/Payment')),
  },
]);

export default routes;
